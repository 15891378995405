import { Card, Elevation } from '@blueprintjs/core';
import type { FC } from 'react';

import styles from './Counts.module.scss';

/**
 *
 */
export const Legend: FC = () => {
  return (
    <Card className={styles.card} elevation={Elevation.ONE}>
      <header className={styles.heading}>Alerts</header>
      <div className={styles.correct}>Correct</div>
      <div className={styles.subCounts}>
        <div className={styles.incorrect}>Incorrect</div>
        <div className={styles.unsure}>Pending Review</div>
        <div className={styles.total}>Total Alerts</div>
        <div className={styles.reads}>Total Reads</div>
      </div>
    </Card>
  );
};
