import classNames from 'classnames';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { VGridLogo } from '~/components/atoms/VGridLogo/VGridLogo';
import { CustomerLogo } from '~atoms/CustomerLogo';

import { DevTag } from './DevTag';
import styles from './Logos.module.scss';

/**
 *
 */
export const Logos: FC = () => {
  return (
    <div className={styles.logos}>
      <Link to="/">
        <VGridLogo className={styles.vgridLogo} />
      </Link>
      <CustomerLogo
        className={classNames(
          styles.customerLogo,
          'vg-site-header__customer-logo'
        )}
      />
      {__DEV__ && DevTag}
    </div>
  );
};
